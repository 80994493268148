// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#courseNameImageSmall {\n  border: 2px solid #fff;\n  border-radius: 50%;\n  background: #ffffff;\n  color: #333;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.course-stat {\n  font-family: Avenir, sans-serif;\n  border-radius: 6px;\n  height: 100%;\n  flex-grow: 1;\n  padding-top: 12px;\n  margin-right: 12px;\n}\n@media screen and (max-width: 1600px) {\n.course-stat {\n    padding-top: 8px;\n    margin-right: 8px;\n}\n}\n.line-clamp {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n.avatar-text {\n  font-weight: 600;\n  color: #666666;\n  font-size: 28px;\n}\n@media screen and (max-width: 1600px) {\n.avatar-text {\n    font-size: 22px;\n}\n}\n.data-section-style {\n  height: 100%;\n  width: 75%;\n  padding: 20px;\n}\n@media screen and (max-width: 1600px) {\n.data-section-style {\n    width: 100%;\n    padding: 10px;\n}\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
