<template>
  <v-card
    :style="
      `border: 1px solid #eee; border-radius: 6px; height: ${sizes.cardHeight}; width: ${width}px;`
    "
    :to="`/teachers/${course.partner_code}/courses/${course.course_id}`"
    class="ft-avenir lg:text-xxs xl:text-xs"
    elevation="0"
    :ripple="false"
  >
    <dialog-delete
      v-model="courseDeleteDialog"
      :deleting="deleting"
      :item="course"
      item-text="Course"
      @confirm-remove="removeCourse(true)"
    ></dialog-delete>
    <div style="height: 100%; width: 100%" class="d-flex">
      <div v-if="screenSize > 800" style="width: 25%">
        <v-img
          v-if="course.image_url !== undefined"
          :src="courseImage"
          style="border-radius: 6px 0 0 6px; height: 100%; width: 100%"
        ></v-img>
        <div
          v-else
          :style="
            'background: #666666; width: 100%; height: 100% ; border-radius: 6px 0px 0px 6px; display: flex; align-items: center; justify-content: center;'
          "
        >
          <v-avatar
            color="white"
            :size="screenSize <= 1600 ? 56 : 88"
            class="avatar-text d-flex align-center justify-center"
          >
            {{ getCourseImageFromTitle(course.course_title) }}
          </v-avatar>
        </div>
      </div>
      <div class="data-section-style">
        <v-container class="pa-0" style="height: 100%; widht: 100%">
          <v-row no-gutters style="height: 35%">
            <h1
              :style="
                `text-overflow: ellipsis; overflow: hidden; width: calc(100% - 30px); font-weight: 600`
              "
              class="ft-avenir font-weight-bold sm:text-base md:text-base lg:text-base xl:text-xl"
            >
              {{ course.course_title }}
            </h1>
            <div>
              <v-menu>
                <template v-slot:activator="{ on: { click }, attrs }">
                  <div
                    class="clickable"
                    v-bind="attrs"
                    style="width: 30px; height: 20px"
                    @click.stop.prevent="click"
                  >
                    <v-icon
                      style="
                        height: 20px;
                        background-color: transparent;
                        color: #202020;
                      "
                      >mdi-dots-vertical</v-icon
                    >
                  </div>
                </template>
                <v-list class="lg:text-xs xl:text-sm">
                  <v-list-item>
                    <v-list-item-title
                      v-if="!$language"
                      disabled
                      class="grey--text px-lg-3 px-xl-4"
                      >Edit Course</v-list-item-title
                    >
                    <v-list-item-title
                      v-else
                      disabled
                      class="grey--text px-lg-3 px-xl-4"
                      >এডিট কোর্স</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                      v-if="!$language"
                      class="clickable px-lg-3 px-xl-4"
                      @click="removeCourse(false)"
                      >Delete Course</v-list-item-title
                    >
                    <v-list-item-title
                      v-else
                      class="clickable px-lg-3 px-xl-4"
                      @click="removeCourse(false)"
                      >ডিলিট কোর্স</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
          <v-row no-gutters justify="space-between" style="height: 30%">
            <slot name="detail-1">
              <v-chip
                v-if="!$language"
                color="primary"
                class="lg:text-xxs xl:text-xxs md:text-xxs sm:text-xxs d-flex align-center"
                :small="$vuetify.breakpoint.lgAndDown"
              >
                <img
                  v-if="screenSize < 600"
                  :src="activeStudentIcon"
                  style="height: 14px; width: 14px; margin-right: 10px"
                  alt=""
                /><span v-else style="margin-right: 10px">Active Students:</span
                >{{ course.num_students }}
              </v-chip>
              <v-chip
                v-else
                color="primary"
                class="lg:text-xxs xl:text-xxs"
                :small="$vuetify.breakpoint.lgAndDown"
                >এক্টিভ শিক্ষার্থী: {{ course.num_students }}
              </v-chip>
            </slot>
            <slot name="detail-2">
              <v-chip
                v-if="!$language"
                outlined
                color="primary"
                class="lg:text-xxs xl:text-xxs md:text-xxs sm:text-xxs"
                :small="$vuetify.breakpoint.lgAndDown"
              >
                {{ course.num_batches }}
                <img
                  v-if="screenSize < 600"
                  :src="activeBatcheIcon"
                  style="height: 14px; width: 14px; margin-left: 10px"
                />
                <span
                  v-else
                  style="margin-left: 6px"
                  >{{ course.num_batches &lt; 2 ? "Batch" : "Batches" }}</span
                >
              </v-chip>
              <v-chip
                v-else
                outlined
                color="primary"
                class="lg:text-xxs xl:text-xxs"
                :small="$vuetify.breakpoint.lgAndDown"
              >
                ব্যাচ সংখ্যা:
                {{ course.num_batches }}
              </v-chip>
            </slot>
          </v-row>
          <v-row
            v-if="fetching && !itemCount"
            no-gutters
            justify="center"
            align="center"
            style="height: 35%"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row v-else no-gutters style="height: 35%">
            <div
              class="course-stat text-center"
              :style="` background-color: #cce2d1;`"
            >
              <p
                v-if="!$language"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                Lecture
              </p>
              <p
                v-else
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                লেকচার
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-0 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '18px'
                }"
              >
                {{ itemCount.num_lectures }}
              </p>
            </div>
            <div
              class="course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center"
              :style="` background-color: #ffdfd2;`"
            >
              <p
                v-if="!$language"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                Assignment
              </p>
              <p
                v-else
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                এসাইনমেন্ট
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-0 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '18px'
                }"
              >
                {{ itemCount.num_assignments }}
              </p>
            </div>
            <div
              class="course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center"
              :style="` background-color: #cce7f5;`"
            >
              <p
                v-if="!$language"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                Exam
              </p>
              <p
                v-else
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                পরীক্ষা
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-0 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '18px'
                }"
              >
                {{ itemCount.num_exams }}
              </p>
            </div>
            <div
              class="course-stat pt-xl-3 pt-lg-2 text-center"
              :style="` background-color: #faccff; margin-right: 0px;`"
            >
              <p
                v-if="!$language"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                Project
              </p>
              <p
                v-else
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '13px'
                }"
              >
                প্রজেক্ট
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-0 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.lgAndDown ? '11px' : '18px'
                }"
              >
                {{ itemCount.num_projects }}
              </p>
            </div>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import courseService from "#ecf/course/services/CourseService";
import { getCourseItemsCount } from "#ecf/courses/actions";
import DialogDelete from "@ecf/global/components/DialogDelete";
export default {
  name: "CardCourseSmall",
  components: { DialogDelete },
  props: {
    course: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      required: false
    },
    width: {
      type: Number
    }
  },
  data() {
    return {
      fetching: true,
      deleting: false,
      courseDeleteDialog: false,
      activeStudentIcon: require("#ecf/courses/assets/Student.svg"),
      activeBatcheIcon: require("#ecf/courses/assets/Batch.svg")
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    courseImage() {
      return "image_url" in this.course
        ? this.course.image_url
        : require("../assets/img.png");
    },
    itemCount() {
      const count = this.$store.state.courses.itemsCount[this.course.course_id];
      if (!count) return {};
      return count;
    },
    sizes() {
      if (this.small) {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "205px" : "210px",
          cardWidth: this.screenSize < 1601 ? "" : "",
          bodyFontSize: ".75rem",
          imageWidth: "30%",
          titleFontSize: "1.125rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "12px" : "14px",
          lineClamp: 2
        };
      } else {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "160px" : "240px",
          cardWidth:
            this.screenSize < 600
              ? "100%"
              : this.screenSize < 801
              ? "243px"
              : this.screenSize < 1601
              ? "336.6px"
              : "491.3px",
          bodyFontSize: ".875rem",
          imageWidth: this.$vuetify.breakpoint.lgAndDown ? "107px" : "140px",
          imageHeight: this.$vuetify.breakpoint.lgAndDown ? "165px" : "240px",
          titleFontSize: "1.5rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "14px" : "16px",
          lineClamp: 2,
          stat: {
            height: this.$vuetify.breakpoint.lgAndDown ? "43px" : "69px"
          }
        };
      }
    }
  },
  async created() {
    try {
      this.fetching = true;
      await getCourseItemsCount(
        this.$store,
        this.course.course_id,
        this.course.partner_code
      );
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message || e]);
    } finally {
      this.fetching = false;
    }
  },
  methods: {
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    async removeCourse(isConfirmed) {
      if (!isConfirmed) {
        this.courseDeleteDialog = true;
        return;
      }
      try {
        this.deleting = true;
        await courseService.delete(this.course);
        this.$emit("deleted");
        this.courseDeleteDialog = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    }
  }
};
</script>

<style lang="scss">
#courseNameImageSmall {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #ffffff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-stat {
  //font-size: 1em;
  font-family: Avenir, sans-serif;
  border-radius: 6px;
  height: 100%;
  flex-grow: 1;
  //min-width: 65px;

  padding-top: 12px;
  margin-right: 12px;

  @media screen and (max-width: 1600px) {
    padding-top: 8px;
    margin-right: 8px;
  }
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.avatar-text {
  font-weight: 600;
  color: #666666;
  font-size: 28px;
  @media screen and (max-width: 1600px) {
    font-size: 22px;
  }
}

.data-section-style {
  height: 100%;
  width: 75%;
  padding: 20px;
  @media screen and (max-width: 1600px) {
    width: 100%;
    padding: 10px;
  }
}
</style>
