<template>
  <div class="card-style d-flex">
    <div class="icon-section d-flex align-center justify-center">
      <img :src="svgIcon" alt="" style="height: 60%; width: 60%" />
    </div>
    <div class="info-section">
      <p
        class="ft-avenir d-flex align-start title-text-style text-truncate"
        style="height: 35%; width: 100%; display: inline-block"
        :style="hidden ? `color: white` : ``"
      >
        {{ title }}
      </p>
      <p
        class="ft-avenir d-flex align-end stat-text-style"
        style="height: 65%; width: 100%; font-weight: 600"
        :style="hidden ? `color: white` : ``"
      >
        {{ stat }}
      </p>
    </div>
  </div>
</template>

<script>
import CoursesSvg from "#ecf/dashboard/assests/icons/course_icon.svg";
import StudentsSvg from "#ecf/dashboard/assests/icons/users_icon.svg";
import EarningsSvg from "#ecf/dashboard/assests/icons/money_icon.svg";

export default {
  name: "CardStatIcon",
  props: {
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    stat: {
      type: [String, Number],
      required: true
    },
    hidden: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    svgIcon() {
      switch (this.icon) {
        case "courses":
          return CoursesSvg;
        case "students":
          return StudentsSvg;
        case "earning":
          return EarningsSvg;
        default:
          return undefined;
      }
    },
    sizes() {
      return {
        padding: this.$vuetify.breakpoint.lgAndDown
          ? `14px 0 12px 17px`
          : `12px 0 12px 26px`,
        textMargin: this.$vuetify.breakpoint.lgAndDown ? "15px" : "20px"
      };
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.card-style {
  background-color: white;
  border: 1px solid #d4ecff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.icon-section {
  background-color: #d4ecff;
  border-radius: 6px 0px 0px 6px;
  width: 30%;
  height: 100%;
}

.info-section {
  width: 70%;
  height: 100%;
  padding: 9% 5% 8% 5%;

  @media screen and (min-width: 801px) and (max-width: 1600px) {
  }

  @media screen and (max-width: 800px) {
    padding: 13px 4%;
    padding-left: 5%;
  }
}

.title-text-style {
  color: #404040;
  font-size: 18px;
  @media screen and (max-width: 801px) {
    font-size: 12px;
  }
}

.stat-text-style {
  font-size: 24px;

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
}
</style>
