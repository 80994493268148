<template>
  <div style="width: 100%">
    <v-toolbar color="primary" dark flat rounded dense class="pl-2">
      <v-toolbar-title v-if="!$language" style="font-size: 1.15rem"
        >Events</v-toolbar-title
      >
      <v-toolbar-title v-else style="font-size: 1.15rem"
        >ইভেন্ট</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <!-- <div v-if="false" class="d-flex justify-end">
        <button
          class="v-btn v-btn--flat v-btn--text px-3 py-2 white--text action"
          style="font-size: 0.875rem !important;"
        >
          See All
        </button>
      </div> -->
      <v-btn
        v-if="small"
        depressed
        plain
        icon
        color="white"
        :ripple="false"
        @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-toolbar>
    <v-list
      style="
        border: 0.12rem solid #eee;
        height: calc(100vh - 205px);
        background-color: white;
        overflow-y: auto;
      "
      class="py-0"
    >
      <div
        v-if="!recentActivities || recentActivities.length === 0"
        class="d-flex flex-column justify-center align-center"
        style="height: calc(100vh - 205px)"
      >
        <v-progress-circular
          v-if="fetching"
          primary
          indeterminate
        ></v-progress-circular>
        <div v-else>
          <div v-if="!$language" style="color: #202020">
            You don't have any events
          </div>
          <div v-else style="color: #202020">আপনার কোনো ইভেন্ট নেই</div>
        </div>
      </div>
      <template v-for="(activity, n) in recentActivities">
        <div :key="n">
          <v-divider class="mx-3"></v-divider>
          <v-list-item :key="n" class="px-lg-4 px-xl-6 pb-0 pt-1">
            <v-list-item-content>
              <v-list-item-title class="mb-3">
                <div class="d-flex">
                  <v-chip
                    v-if="activity.course_title"
                    color="primary"
                    outlined
                    class="ft-avenir ft-light lg:text-xxs xl:text-xs"
                    style="margin-right: 6px"
                    >{{ activity.course_title }}</v-chip
                  >
                  <v-chip
                    v-if="activity.partner_name"
                    style="color: #fc6222"
                    class="ft-avenir ft-light lg:text-xxs xl:text-xs border-orange"
                    outlined
                    >{{ activity.partner_name }}</v-chip
                  >
                  <v-spacer></v-spacer>
                  <span
                    class="ft-avenir ft-light lg:text-xs xl:text-sm"
                    style="color: #a4a4a4"
                  >
                    {{ date_diff(activity.date) }}
                  </span>
                </div>
              </v-list-item-title>
              <div class="ft-avenir lg:text-xs xl:text-sm">
                <div
                  class="line-clamp"
                  style="
                    height: calc(2 * 1.35em);
                    word-break: break-word;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  "
                >
                  {{ activity.message }}
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </div>
</template>

<script>
import { getRecentActivities } from "#ecf/dashboard/actions";
import moment from "moment";
import { get } from "lodash";

export default {
  name: "ListRecentActivity",
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fetching: false
    };
  },
  computed: {
    ownPartnerCode() {
      const access = this.$store.state.user.user.access;

      for (let [partner_code, access_] of Object.entries(access)) {
        if (access_.includes("owner")) return partner_code;
      }
      return "";
    },
    recentActivities() {
      const activities = this.$store.state.dashboard.recentActivities;
      if (!activities) return undefined;
      return activities.map(a => ({
        course_title: a["course_title"],
        partner_name:
          a.partner_code !== this.ownPartnerCode &&
          get(
            this.$store.state.organization.organizationMap[a.partner_code],
            "partner_name",
            "Wrong Partner"
          ),
        message: a["message"],
        created_at: a["created_at"],
        date: a["date"]
      }));
    }
  },
  async created() {
    this.fetching = true;
    try {
      await getRecentActivities(this.$store);
    } catch (e) {
      this.$root.$emit("alert", ["Alert", e])
    } finally {
      this.fetching = false;
    }
  },
  methods: {
    date_diff(date) {
      return moment(date, "YYYY-MM-DD").fromNow(true);
    }
  }
};
</script>

<style lang="scss"></style>
