var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-style d-flex" }, [
    _c(
      "div",
      { staticClass: "icon-section d-flex align-center justify-center" },
      [
        _c("img", {
          staticStyle: { height: "60%", width: "60%" },
          attrs: { src: _vm.svgIcon, alt: "" },
        }),
      ]
    ),
    _c("div", { staticClass: "info-section" }, [
      _c(
        "p",
        {
          staticClass:
            "ft-avenir d-flex align-start title-text-style text-truncate",
          staticStyle: {
            height: "35%",
            width: "100%",
            display: "inline-block",
          },
          style: _vm.hidden ? `color: white` : ``,
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c(
        "p",
        {
          staticClass: "ft-avenir d-flex align-end stat-text-style",
          staticStyle: { height: "65%", width: "100%", "font-weight": "600" },
          style: _vm.hidden ? `color: white` : ``,
        },
        [_vm._v(" " + _vm._s(_vm.stat) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }