<template>
  <div>
    <v-container
      :style="{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: screenSize <= 800 ? '5px' : '28px'
      }"
    >
      <v-row no-gutters class="d-flex">
        <v-col
          :order="screenSize <= 800 ? 2 : 1"
          :cols="screenSize <= 800 ? '12' : ''"
          :style="restStyle"
          class="pa-0"
        >
          <div>
            <div class="d-flex justify-space-between align-center">
              <v-card
                elevation="0"
                class="border-1-2 border-solid border-primary bg-transparent"
                :style="
                  `width: ${screenSize < 801 ? 'calc(100% - 60px)' : '100%'};`
                "
              >
                <v-card-text
                  class="black--text welcome-card-style"
                  :style="`padding: ${screenSize < 801 ? '7px 10px' : '15px'};`"
                >
                  <div>
                    <span class="ft-light welcome-text"
                      >{{ !$language ? "Welcome back, " : " স্বাগতম, " }}
                    </span>
                    <span
                      class="primary--text ft-avenir ft-medium teacher-name-text"
                      >{{ username }}</span
                    >
                  </div>
                </v-card-text>
              </v-card>
              <v-dialog v-model="eventDialogueActive">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-show="screenSize < 801"
                    icon
                    min-width="35"
                    widht="35"
                    height="35"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img :src="eventIcon" alt="" />
                  </v-btn>
                </template>
                <list-recent-activity
                  small
                  @close="eventDialogueActive = false"
                ></list-recent-activity>
              </v-dialog>
            </div>
            <div
              :style="`width: 100%; height: ${statsHeight}px;`"
              class="mt-5 d-flex align-center justify-center"
            >
              <v-progress-circular
                v-if="!this.stats && this.fetchingStats"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div
                v-else
                :style="
                  `height: 100%; width: 100%; display: grid; grid-template-columns: repeat(${statGridDim.count}, 1fr); grid-template-rows: ${statGridDim.height}px; gap: ${statGridDim.gap}px`
                "
              >
                <card-stat-icon
                  v-for="(stat, n) in !$language ? stats : statsBN"
                  :key="n"
                  :title="stat.title"
                  :stat="stat.stat"
                  :icon="stat.icon"
                  :hidden="stat.hidden"
                ></card-stat-icon>
              </div>
            </div>
            <div
              :style="`width: 100%; height: ${pendingHeight}px;`"
              class="mt-5 d-flex align-center justify-center"
            >
              <v-progress-circular
                v-if="!pendingActivities && fetchingActivities"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <div
                v-else
                class="grid"
                :style="
                  `height: 100%; width: 100%; display: grid; grid-template-columns: repeat(${pendingGridDim.count}, 1fr); grid-template-rows: ${pendingGridDim.height}px; gap: ${pendingGridDim.gap}px`
                "
              >
                <div
                  v-for="(activity, n) in !$language
                    ? pendingActivities
                    : pendingActivitiesBN"
                  :key="n"
                  :style="
                    screenSize < 600 ? `grid-column: span ${activity.span}` : ``
                  "
                >
                  <card-activity-pending
                    :data-label="n"
                    :title="activity.title"
                    :batch-count="activity.batchCount"
                  ></card-activity-pending>
                </div>
              </div>
            </div>
            <div>
              <div
                class="d-flex justify-space-between align-center my-course-header"
              >
                <h1 class="ft-medium" style="color: #202020">
                  {{ !$language ? "My Courses" : "আমার কোর্সসমূহ" }}
                </h1>
                <v-btn depressed text color="primary" to="/teachers/courses">{{
                  !$language ? "See All" : "আরোও দেখুন"
                }}</v-btn>
              </div>
              <div
                v-if="!courses || courses.length === 0"
                style="height: 240px; background-color: #fff; border-color: #ccc"
                class="d-flex justify-center align-center border border-solid border-radius-8"
              >
                <v-progress-circular
                  v-if="fetchingCourses"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <div v-else class="text-center">
                  <p class="mb-0 pb-0">{{!$language ? "You don't have any courses" : "আপনার কোনো কোর্স নেই"}}</p>
                </div>
              </div>
              <div id="dash-slider-section">
                <v-slide-group
                  v-if="screenSize > 600"
                  class="course-slide"
                  :show-arrows="true"
                >
                  <v-slide-item v-for="(course, n) in courses" :key="n">
                    <card-course-small
                      :course="course"
                      :width="sliderItemWidth()"
                      :style="`margin-right: ${n % 2 == 0 ? 10 : 0}px`"
                      @deleted="removeMyCourseFromStore(course)"
                    >
                    </card-course-small>
                  </v-slide-item>
                </v-slide-group>
                <div v-else class="d-flex flex-column">
                  <card-course-small
                    v-for="(course, n) in courses"
                    :key="n"
                    style="margin-bottom: 5px"
                    :course="course"
                    :width="sliderItemWidth()"
                    @deleted="removeMyCourseFromStore(course)"
                  >
                  </card-course-small>
                </div>
              </div>
            </div>
          </div>
          <!--          <div style="margin: 0 -12px;">-->
          <!--            <swiper :modules="modules" :slides-per-view="2" :space-between="30">-->
          <!--              <swiper-slide v-for="(course, n) in courses" :key="n">-->
          <!--                <card-course small :course="course"> </card-course>-->
          <!--              </swiper-slide>-->
          <!--            </swiper>-->
          <!--          </div>-->
          <!--        </div>-->
        </v-col>
        <v-col
          :order="screenSize <= 800 ? 1 : 2"
          :cols="screenSize <= 800 ? '12' : ''"
          :style="eventsOffersStyle"
          class="pa-0"
        >
<!--          commented out current plan indication as the requirement is not fully clear, fetching data from backend though -->
<!--          <div v-if="fetchingSubscription" class="d-flex justify-center py-3">-->
<!--            <v-progress-circular indeterminate></v-progress-circular>-->
<!--          </div>-->
<!--          <div-->
<!--            v-else-->
<!--            class="d-flex flex-grow-1 border-1-2 border-solid border-primary mb-3 plan-section-style"-->
<!--            style="border-radius: 6px; background-color: #ebf2ff"-->
<!--          >-->
<!--            <div-->
<!--              class="orange white&#45;&#45;text text-center px-5 py-2 text-truncate d-flex align-center justify-center"-->
<!--            >-->
<!--              <div>-->
<!--                <p class="ft-avenir ft-light text-truncate">-->
<!--                  {{ !$language ? "Current Plan" : "বর্তমান প্ল্যান" }}-->
<!--                </p>-->
<!--                <p-->
<!--                  class="ft-avenir text-uppercase text-truncate white&#45;&#45;text"-->
<!--                  style="font-weight: 600"-->
<!--                >-->
<!--                  {{ subscription.plan }}-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--              v-if="subscription.plan == 'ADVANCED'"-->
<!--              class="flex-grow-1 ft-avenir orange&#45;&#45;text py-2 px-3 text-truncate"-->
<!--            >-->
<!--              {{-->
<!--                !$language-->
<!--                  ? "Your Last Subscription Will Expires"-->
<!--                  : "আপনার সাবস্ক্রিপশনের মেয়াদ শেষ হবে"-->
<!--              }}-->
<!--              <br />-->
<!--              {{ !$language ? "On" : "" }} {{ format(subscription.deadline) }}-->
<!--              <br />-->
<!--              <span class="black&#45;&#45;text ft-medium"-->
<!--                >{{-->
<!--                  !$language ? "Subscription Total For " : "মোট সাবস্ক্রিপশন "-->
<!--                }}{{ subscription.num_students }}-->
<!--                {{-->
<!--                  !$language-->
<!--                    ? subscription.num_students > 1-->
<!--                      ? "Students"-->
<!--                      : "Student"-->
<!--                    : "জন শিক্ষার্থী"-->
<!--                }}</span-->
<!--              >-->
<!--            </div>-->
<!--            <div v-else class="flex-grow-1 ft-avenir py-2 px-3 text-truncate">-->
<!--              {{-->
<!--                !$language-->
<!--                  ? "Upgrade your subscription now"-->
<!--                  : "এখনি আপনার সাবস্ক্রিপশন আপগ্রেড করুন"-->
<!--              }}-->
<!--            </div>-->
<!--            &lt;!&ndash; <v-icon color="orange" medium class="pr-2"-->
<!--            >keyboard_arrow_right</v-icon-->
<!--          > &ndash;&gt;-->
<!--          </div>-->
          <div v-if="screenSize > 800">
            <list-recent-activity></list-recent-activity>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getOwnCourses } from "#ecf/courses/actions";
import CardCourse from "#ecf/courses/components/CardCourse";
import CardCourseSmall from "#ecf/courses/components/CardCourseSmall";
import {
  getPendingActivities,
  getTotalStats,
  getSubscription
} from "#ecf/dashboard/actions";
import CardActivityPending from "#ecf/dashboard/components/CardActivityPending";
import CardStatIcon from "#ecf/dashboard/components/CardStatIcon";
import ListRecentActivity from "#ecf/dashboard/components/ListRecentActivity";
// import { SwiperSlide, Swiper } from "swiper/vue";
// import { Navigation } from "swiper";

export default {
  name: "Dashboard",
  components: {
    CardCourseSmall,
    ListRecentActivity,
    CardActivityPending,
    CardStatIcon
    // Swiper,
    // SwiperSlide,
  },
  props: {},
  data() {
    return {
      // modules: [Navigation],
      username: this.$store.state.user.user.name,
      fetchingStats: true,
      fetchingCourses: true,
      fetchingActivities: true,
      fetchingSubscription: true,
      subscription: { plan: "", deadline: "", num_students: 0 },
      eventIcon: require("#ecf/dashboard/assests/icons/activity.svg"),
      eventDialogueActive: false
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    // sliderItemWidth(){
    //   let s = this.screenSize;
    //   let slideElementWidth = document.getElementById("dash-slider-section").offsetWidth;
    //   let slider
    //   if(s < 600){
    //     return slideElementWidth;
    //   }else{
    //     return (slideElementWidth-10)/2;
    //   }
    // },
    statsHeight() {
      return this.screenSize < 600
        ? 250
        : this.screenSize < 801
        ? 80
        : this.screenSize < 1601
        ? 100
        : 130;
    },
    pendingHeight() {
      return this.screenSize < 600
        ? 175
        : this.screenSize < 801
        ? 80
        : this.screenSize < 1601
        ? 100
        : 130;
    },
    statGridDim() {
      let s = this.screenSize;
      return {
        height: s < 801 ? 80 : s < 1601 ? 100 : 130,
        count: s < 600 ? 1 : 3,
        gap: s < 600 ? 5 : s < 801 ? 10 : s < 1601 ? 15 : 20
      };
    },
    pendingGridDim() {
      let s = this.screenSize;
      return {
        height: s < 801 ? 80 : s < 1601 ? 100 : 130,
        count: s < 600 ? 2 : 3,
        gap: s < 600 ? 15 : s < 801 ? 10 : s < 1601 ? 15 : 20
      };
    },
    size() {
      return {
        col1Width: this.$vuetify.breakpoint.width <= 800 ? "100%" : "70%",
        col2Width: this.$vuetify.breakpoint.width <= 800 ? "100%" : "27%",
        col2MarginLeft: "3%",
        iconCard: {
          height:
            this.screenSize < 600
              ? "80px"
              : this.screenSize < 801
              ? "65px"
              : this.screenSize < 1601
              ? "90px"
              : "130px",
          count: this.screenSize <= 600 ? 1 : 3,
          textMargin: this.$vuetify.breakpoint.lgAndDown ? "15px" : "20px",
          gap: this.$vuetify.breakpoint.lgAndDown ? "15px" : "18px"
        },
        pendingCard: {
          count: this.screenSize <= 600 ? 1 : 3,
          height: this.$vuetify.breakpoint.lgAndDown ? "105px" : "143px",
          width: this.$vuetify.breakpoint.lgAndDown ? "222px" : "325px",
          gap: this.$vuetify.breakpoint.lgAndDown ? "15px" : "18px"
        }
      };
    },
    stats() {
      const stats = this.$store.state.dashboard.totalStats;
      if (stats === undefined) {
        return undefined;
      } else {
        return [
          {
            title: "Total Courses",
            stat: stats.num_courses,
            icon: "courses",
            hidden: false
          },
          {
            title: "Total Students",
            stat: stats.num_students,
            icon: "students",
            hidden: false
          },
          {
            title: "Value",
            stat: "Value",
            icon: "earning",
            hidden: true
          }
        ];
      }
    },
    eventsOffersStyle() {
      return this.screenSize <= 800 ? `` : `max-width: 27%; margin-left: 3%`;
    },
    restStyle() {
      return this.screenSize <= 800 ? `` : `max-width: 70%`;
    },
    statsBN() {
      const stats = this.$store.state.dashboard.totalStats;
      if (stats === undefined) {
        return undefined;
      } else {
        return [
          {
            title: "মোট কোর্স",
            stat: stats.num_courses,
            icon: "courses"
          },
          {
            title: "মোট শিক্ষার্থী",
            stat: stats.num_students,
            icon: "students"
          },
          {
            title: "",
            stat: "",
            icon: "earning"
          }
        ];
      }
    },
    courses() {
      if (this.$store.state.courses.myCourses === undefined) return undefined;
      return this.$store.state.courses.myCourses;
    },
    pendingActivitiesBN() {
      const activities = this.$store.state.dashboard.pendingActivities;
      if (activities === undefined) return undefined;
      return [
        {
          title: "অবশিষ্ট এসাইনমেন্ট মূল্যায়ন",
          batchCount: activities.num_unevaluated_assignments
        },
        {
          title: "অবশিষ্ট প্রজেক্ট মূল্যায়ন",
          batchCount: activities.num_unevaluated_projects
        },
        {
          title: "অবশিষ্ট পরীক্ষা মূল্যায়ন",
          batchCount: activities.num_unevaluated_exams
        }
      ];
    },
    pendingActivities() {
      const activities = this.$store.state.dashboard.pendingActivities;
      if (activities === undefined) return undefined;
      return [
        {
          title: "Assignment Evaluation Pending",
          batchCount: activities.num_unevaluated_assignments,
          span: 1
        },
        {
          title: "Project Evaluation Pending",
          batchCount: activities.num_unevaluated_projects,
          span: 1
        },
        {
          title: "Exam Evaluation Pending",
          batchCount: activities.num_unevaluated_exams,
          span: 2
        }
      ];
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    await Promise.all([this.fetchCourses(), this.fetchActivities()]);
    await this.fetchStats();
    await this.fetchSubscription();
  },
  methods: {
    sliderItemWidth() {
      let s = this.screenSize;
      if (s < 600) {
        return "100%";
      }
      // let slideElementWidth = document.getElementById(
      //   "dash-slider-section",
      // ).offsetWidth;
      let slideElement = document.getElementById("dash-slider-section");
      if (!slideElement) return Math.floor(s * 0.25);
      let slideElementWidth = slideElement.offsetWidth;

      return (slideElementWidth - 10) / 2;
    },
    async fetchCourses() {
      try {
        this.fetchingCourses = true;
        await getOwnCourses(this.$store);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message || e]);
      } finally {
        this.fetchingCourses = false;
      }
    },
    async fetchStats() {
      this.fetchingStats = true;
      try {
        await getTotalStats(this.$store);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message || e]);
      } finally {
        this.fetchingStats = false;
      }
    },
    async fetchActivities() {
      try {
        this.fetchingActivities = true;
        await getPendingActivities(this.$store);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message || e]);
      } finally {
        this.fetchingActivities = false;
      }
    },
    async fetchSubscription() {
      this.fetchingSubscription = true;
      this.subscription = await getSubscription();
      this.fetchingSubscription = false;
    },
    removeMyCourseFromStore(course) {
      this.$store.commit("courses/removeMyCourse", {
        courseId: course.course_id
      });
    },
    format(dateString) {
      let d = new Date(dateString);
      if (!this.$language)
        return d.toLocaleString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric"
        });
      else
        return d.toLocaleString("bn-BD", {
          day: "numeric",
          month: "short",
          year: "numeric"
        });
    }
  }
};
</script>

<style lang="scss">
.course-slide {
  .v-icon {
    background-color: #000000;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    color: #fff;
    z-index: 2;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    z-index: 1;
  }
  .v-slide-group__prev:before,
  .v-slide-group__next:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    z-index: 79;
    opacity: 0.4;
    background: transparent linear-gradient(270deg, #000000 0%, #00000033 100%)
      0% 0% no-repeat padding-box;
  }
  .v-slide-group__prev:before {
    border-radius: 6px 0 0 6px;
  }
  .v-slide-group__next:before {
    border-radius: 0px 6px 6px 0px;
  }
  .v-slide-group__next {
    right: 0px;
    border-radius: 0px 6px 6px 0px;
  }
  .v-slide-group__prev {
    left: 0px;
    border-radius: 6px 0px 0px 6px;
  }
  .v-slide-group__prev--disabled,
  .v-slide-group__next--disabled {
    visibility: hidden;
  }
}

.welcome-card-style {
  @media screen and (min-width: 1601px) {
    padding: 16px 20px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    padding: 12px 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    padding: 12px 16px;
  }

  @media screen and (max-width: 599px) {
    padding: 5px 8px;
  }
}

.welcome-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.teacher-name-text {
  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 16px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.plan-section-style {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.my-course-header {
  @media screen and (min-width: 1601px) {
    margin: 25px 10px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    margin: 15px 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    margin: 15px 5px;
  }

  @media screen and (max-width: 599px) {
    margin: 10px 5px;
  }

  h1 {
    @media screen and (min-width: 1601px) {
      font-size: 24px;
    }

    @media screen and (min-width: 801px) and (max-width: 1600px) {
      font-size: 20px;
    }

    @media screen and (min-width: 600px) and (max-width: 800px) {
      font-size: 18px;
    }

    @media screen and (max-width: 599px) {
      font-size: 16px;
    }
  }
}

.event-xs-button-style {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.events-xs-style {
  @media screen and (min-width: 1601px) {
    font-size: 16px;
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}
</style>
