var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "pl-2",
          attrs: {
            color: "primary",
            dark: "",
            flat: "",
            rounded: "",
            dense: "",
          },
        },
        [
          !_vm.$language
            ? _c(
                "v-toolbar-title",
                { staticStyle: { "font-size": "1.15rem" } },
                [_vm._v("Events")]
              )
            : _c(
                "v-toolbar-title",
                { staticStyle: { "font-size": "1.15rem" } },
                [_vm._v("ইভেন্ট")]
              ),
          _c("v-spacer"),
          _vm.small
            ? _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    plain: "",
                    icon: "",
                    color: "white",
                    ripple: false,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-list",
        {
          staticClass: "py-0",
          staticStyle: {
            border: "0.12rem solid #eee",
            height: "calc(100vh - 205px)",
            "background-color": "white",
            "overflow-y": "auto",
          },
        },
        [
          !_vm.recentActivities || _vm.recentActivities.length === 0
            ? _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                  staticStyle: { height: "calc(100vh - 205px)" },
                },
                [
                  _vm.fetching
                    ? _c("v-progress-circular", {
                        attrs: { primary: "", indeterminate: "" },
                      })
                    : _c("div", [
                        !_vm.$language
                          ? _c("div", { staticStyle: { color: "#202020" } }, [
                              _vm._v(" You don't have any events "),
                            ])
                          : _c("div", { staticStyle: { color: "#202020" } }, [
                              _vm._v("আপনার কোনো ইভেন্ট নেই"),
                            ]),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.recentActivities, function (activity, n) {
            return [
              _c(
                "div",
                { key: n },
                [
                  _c("v-divider", { staticClass: "mx-3" }),
                  _c(
                    "v-list-item",
                    { key: n, staticClass: "px-lg-4 px-xl-6 pb-0 pt-1" },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", { staticClass: "mb-3" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                activity.course_title
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass:
                                          "ft-avenir ft-light lg:text-xxs xl:text-xs",
                                        staticStyle: { "margin-right": "6px" },
                                        attrs: {
                                          color: "primary",
                                          outlined: "",
                                        },
                                      },
                                      [_vm._v(_vm._s(activity.course_title))]
                                    )
                                  : _vm._e(),
                                activity.partner_name
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass:
                                          "ft-avenir ft-light lg:text-xxs xl:text-xs border-orange",
                                        staticStyle: { color: "#fc6222" },
                                        attrs: { outlined: "" },
                                      },
                                      [_vm._v(_vm._s(activity.partner_name))]
                                    )
                                  : _vm._e(),
                                _c("v-spacer"),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ft-avenir ft-light lg:text-xs xl:text-sm",
                                    staticStyle: { color: "#a4a4a4" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.date_diff(activity.date)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "ft-avenir lg:text-xs xl:text-sm" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "line-clamp",
                                  staticStyle: {
                                    height: "calc(2 * 1.35em)",
                                    "word-break": "break-word",
                                    "-webkit-line-clamp": "2",
                                    overflow: "hidden",
                                  },
                                },
                                [_vm._v(" " + _vm._s(activity.message) + " ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }