var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "ft-avenir lg:text-xxs xl:text-xs",
      style: `border: 1px solid #eee; border-radius: 6px; height: ${_vm.sizes.cardHeight}; width: ${_vm.width}px;`,
      attrs: {
        to: `/teachers/${_vm.course.partner_code}/courses/${_vm.course.course_id}`,
        elevation: "0",
        ripple: false,
      },
    },
    [
      _c("dialog-delete", {
        attrs: {
          deleting: _vm.deleting,
          item: _vm.course,
          "item-text": "Course",
        },
        on: {
          "confirm-remove": function ($event) {
            return _vm.removeCourse(true)
          },
        },
        model: {
          value: _vm.courseDeleteDialog,
          callback: function ($$v) {
            _vm.courseDeleteDialog = $$v
          },
          expression: "courseDeleteDialog",
        },
      }),
      _c(
        "div",
        {
          staticClass: "d-flex",
          staticStyle: { height: "100%", width: "100%" },
        },
        [
          _vm.screenSize > 800
            ? _c(
                "div",
                { staticStyle: { width: "25%" } },
                [
                  _vm.course.image_url !== undefined
                    ? _c("v-img", {
                        staticStyle: {
                          "border-radius": "6px 0 0 6px",
                          height: "100%",
                          width: "100%",
                        },
                        attrs: { src: _vm.courseImage },
                      })
                    : _c(
                        "div",
                        {
                          style:
                            "background: #666666; width: 100%; height: 100% ; border-radius: 6px 0px 0px 6px; display: flex; align-items: center; justify-content: center;",
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass:
                                "avatar-text d-flex align-center justify-center",
                              attrs: {
                                color: "white",
                                size: _vm.screenSize <= 1600 ? 56 : 88,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getCourseImageFromTitle(
                                      _vm.course.course_title
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "data-section-style" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-0",
                  staticStyle: { height: "100%", widht: "100%" },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "35%" },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "ft-avenir font-weight-bold sm:text-base md:text-base lg:text-base xl:text-xl",
                          style: `text-overflow: ellipsis; overflow: hidden; width: calc(100% - 30px); font-weight: 600`,
                        },
                        [_vm._v(" " + _vm._s(_vm.course.course_title) + " ")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on: { click }, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._b(
                                          {
                                            staticClass: "clickable",
                                            staticStyle: {
                                              width: "30px",
                                              height: "20px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return click.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                height: "20px",
                                                "background-color":
                                                  "transparent",
                                                color: "#202020",
                                              },
                                            },
                                            [_vm._v("mdi-dots-vertical")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "lg:text-xs xl:text-sm" },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      !_vm.$language
                                        ? _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "grey--text px-lg-3 px-xl-4",
                                              attrs: { disabled: "" },
                                            },
                                            [_vm._v("Edit Course")]
                                          )
                                        : _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "grey--text px-lg-3 px-xl-4",
                                              attrs: { disabled: "" },
                                            },
                                            [_vm._v("এডিট কোর্স")]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      !_vm.$language
                                        ? _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "clickable px-lg-3 px-xl-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeCourse(false)
                                                },
                                              },
                                            },
                                            [_vm._v("Delete Course")]
                                          )
                                        : _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "clickable px-lg-3 px-xl-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeCourse(false)
                                                },
                                              },
                                            },
                                            [_vm._v("ডিলিট কোর্স")]
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "30%" },
                      attrs: { "no-gutters": "", justify: "space-between" },
                    },
                    [
                      _vm._t("detail-1", function () {
                        return [
                          !_vm.$language
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "lg:text-xxs xl:text-xxs md:text-xxs sm:text-xxs d-flex align-center",
                                  attrs: {
                                    color: "primary",
                                    small: _vm.$vuetify.breakpoint.lgAndDown,
                                  },
                                },
                                [
                                  _vm.screenSize < 600
                                    ? _c("img", {
                                        staticStyle: {
                                          height: "14px",
                                          width: "14px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          src: _vm.activeStudentIcon,
                                          alt: "",
                                        },
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [_vm._v("Active Students:")]
                                      ),
                                  _vm._v(_vm._s(_vm.course.num_students) + " "),
                                ]
                              )
                            : _c(
                                "v-chip",
                                {
                                  staticClass: "lg:text-xxs xl:text-xxs",
                                  attrs: {
                                    color: "primary",
                                    small: _vm.$vuetify.breakpoint.lgAndDown,
                                  },
                                },
                                [
                                  _vm._v(
                                    "এক্টিভ শিক্ষার্থী: " +
                                      _vm._s(_vm.course.num_students) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      }),
                      _vm._t("detail-2", function () {
                        return [
                          !_vm.$language
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "lg:text-xxs xl:text-xxs md:text-xxs sm:text-xxs",
                                  attrs: {
                                    outlined: "",
                                    color: "primary",
                                    small: _vm.$vuetify.breakpoint.lgAndDown,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.course.num_batches) + " "
                                  ),
                                  _vm.screenSize < 600
                                    ? _c("img", {
                                        staticStyle: {
                                          height: "14px",
                                          width: "14px",
                                          "margin-left": "10px",
                                        },
                                        attrs: { src: _vm.activeBatcheIcon },
                                      })
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-left": "6px" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.course.num_batches < 2
                                                ? "Batch"
                                                : "Batches"
                                            )
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            : _c(
                                "v-chip",
                                {
                                  staticClass: "lg:text-xxs xl:text-xxs",
                                  attrs: {
                                    outlined: "",
                                    color: "primary",
                                    small: _vm.$vuetify.breakpoint.lgAndDown,
                                  },
                                },
                                [
                                  _vm._v(
                                    " ব্যাচ সংখ্যা: " +
                                      _vm._s(_vm.course.num_batches) +
                                      " "
                                  ),
                                ]
                              ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm.fetching && !_vm.itemCount
                    ? _c(
                        "v-row",
                        {
                          staticStyle: { height: "35%" },
                          attrs: {
                            "no-gutters": "",
                            justify: "center",
                            align: "center",
                          },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        {
                          staticStyle: { height: "35%" },
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "course-stat text-center",
                              style: ` background-color: #cce2d1;`,
                            },
                            [
                              !_vm.$language
                                ? _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" Lecture ")]
                                  )
                                : _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" লেকচার ")]
                                  ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ft-medium mt-xl-3 mt-lg-0 ft-avenir",
                                  style: {
                                    fontSize: this.$vuetify.breakpoint.lgAndDown
                                      ? "11px"
                                      : "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.itemCount.num_lectures) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center",
                              style: ` background-color: #ffdfd2;`,
                            },
                            [
                              !_vm.$language
                                ? _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" Assignment ")]
                                  )
                                : _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" এসাইনমেন্ট ")]
                                  ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ft-medium mt-xl-3 mt-lg-0 ft-avenir",
                                  style: {
                                    fontSize: this.$vuetify.breakpoint.lgAndDown
                                      ? "11px"
                                      : "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.itemCount.num_assignments) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "course-stat pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 text-center",
                              style: ` background-color: #cce7f5;`,
                            },
                            [
                              !_vm.$language
                                ? _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" Exam ")]
                                  )
                                : _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" পরীক্ষা ")]
                                  ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ft-medium mt-xl-3 mt-lg-0 ft-avenir",
                                  style: {
                                    fontSize: this.$vuetify.breakpoint.lgAndDown
                                      ? "11px"
                                      : "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.itemCount.num_exams) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "course-stat pt-xl-3 pt-lg-2 text-center",
                              style: ` background-color: #faccff; margin-right: 0px;`,
                            },
                            [
                              !_vm.$language
                                ? _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" Project ")]
                                  )
                                : _c(
                                    "p",
                                    {
                                      style: {
                                        fontSize: this.$vuetify.breakpoint
                                          .lgAndDown
                                          ? "11px"
                                          : "13px",
                                      },
                                    },
                                    [_vm._v(" প্রজেক্ট ")]
                                  ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ft-medium mt-xl-3 mt-lg-0 ft-avenir",
                                  style: {
                                    fontSize: this.$vuetify.breakpoint.lgAndDown
                                      ? "11px"
                                      : "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.itemCount.num_projects) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }