var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          style: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: _vm.screenSize <= 800 ? "5px" : "28px",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "d-flex", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  style: _vm.restStyle,
                  attrs: {
                    order: _vm.screenSize <= 800 ? 2 : 1,
                    cols: _vm.screenSize <= 800 ? "12" : "",
                  },
                },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-between align-center",
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "border-1-2 border-solid border-primary bg-transparent",
                            style: `width: ${
                              _vm.screenSize < 801
                                ? "calc(100% - 60px)"
                                : "100%"
                            };`,
                            attrs: { elevation: "0" },
                          },
                          [
                            _c(
                              "v-card-text",
                              {
                                staticClass: "black--text welcome-card-style",
                                style: `padding: ${
                                  _vm.screenSize < 801 ? "7px 10px" : "15px"
                                };`,
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "ft-light welcome-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "Welcome back, "
                                            : " স্বাগতম, "
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "primary--text ft-avenir ft-medium teacher-name-text",
                                    },
                                    [_vm._v(_vm._s(_vm.username))]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-dialog",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.screenSize < 801,
                                                expression: "screenSize < 801",
                                              },
                                            ],
                                            attrs: {
                                              icon: "",
                                              "min-width": "35",
                                              widht: "35",
                                              height: "35",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.eventIcon,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.eventDialogueActive,
                              callback: function ($$v) {
                                _vm.eventDialogueActive = $$v
                              },
                              expression: "eventDialogueActive",
                            },
                          },
                          [
                            _c("list-recent-activity", {
                              attrs: { small: "" },
                              on: {
                                close: function ($event) {
                                  _vm.eventDialogueActive = false
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-5 d-flex align-center justify-center",
                        style: `width: 100%; height: ${_vm.statsHeight}px;`,
                      },
                      [
                        !this.stats && this.fetchingStats
                          ? _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" },
                            })
                          : _c(
                              "div",
                              {
                                style: `height: 100%; width: 100%; display: grid; grid-template-columns: repeat(${_vm.statGridDim.count}, 1fr); grid-template-rows: ${_vm.statGridDim.height}px; gap: ${_vm.statGridDim.gap}px`,
                              },
                              _vm._l(
                                !_vm.$language ? _vm.stats : _vm.statsBN,
                                function (stat, n) {
                                  return _c("card-stat-icon", {
                                    key: n,
                                    attrs: {
                                      title: stat.title,
                                      stat: stat.stat,
                                      icon: stat.icon,
                                      hidden: stat.hidden,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-5 d-flex align-center justify-center",
                        style: `width: 100%; height: ${_vm.pendingHeight}px;`,
                      },
                      [
                        !_vm.pendingActivities && _vm.fetchingActivities
                          ? _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" },
                            })
                          : _c(
                              "div",
                              {
                                staticClass: "grid",
                                style: `height: 100%; width: 100%; display: grid; grid-template-columns: repeat(${_vm.pendingGridDim.count}, 1fr); grid-template-rows: ${_vm.pendingGridDim.height}px; gap: ${_vm.pendingGridDim.gap}px`,
                              },
                              _vm._l(
                                !_vm.$language
                                  ? _vm.pendingActivities
                                  : _vm.pendingActivitiesBN,
                                function (activity, n) {
                                  return _c(
                                    "div",
                                    {
                                      key: n,
                                      style:
                                        _vm.screenSize < 600
                                          ? `grid-column: span ${activity.span}`
                                          : ``,
                                    },
                                    [
                                      _c("card-activity-pending", {
                                        attrs: {
                                          "data-label": n,
                                          title: activity.title,
                                          "batch-count": activity.batchCount,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                      ],
                      1
                    ),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center my-course-header",
                        },
                        [
                          _c(
                            "h1",
                            {
                              staticClass: "ft-medium",
                              staticStyle: { color: "#202020" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    !_vm.$language
                                      ? "My Courses"
                                      : "আমার কোর্সসমূহ"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                text: "",
                                color: "primary",
                                to: "/teachers/courses",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  !_vm.$language ? "See All" : "আরোও দেখুন"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      !_vm.courses || _vm.courses.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center align-center border border-solid border-radius-8",
                              staticStyle: {
                                height: "240px",
                                "background-color": "#fff",
                                "border-color": "#ccc",
                              },
                            },
                            [
                              _vm.fetchingCourses
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  })
                                : _c("div", { staticClass: "text-center" }, [
                                    _c("p", { staticClass: "mb-0 pb-0" }, [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.$language
                                            ? "You don't have any courses"
                                            : "আপনার কোনো কোর্স নেই"
                                        )
                                      ),
                                    ]),
                                  ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { attrs: { id: "dash-slider-section" } },
                        [
                          _vm.screenSize > 600
                            ? _c(
                                "v-slide-group",
                                {
                                  staticClass: "course-slide",
                                  attrs: { "show-arrows": true },
                                },
                                _vm._l(_vm.courses, function (course, n) {
                                  return _c(
                                    "v-slide-item",
                                    { key: n },
                                    [
                                      _c("card-course-small", {
                                        style: `margin-right: ${
                                          n % 2 == 0 ? 10 : 0
                                        }px`,
                                        attrs: {
                                          course: course,
                                          width: _vm.sliderItemWidth(),
                                        },
                                        on: {
                                          deleted: function ($event) {
                                            return _vm.removeMyCourseFromStore(
                                              course
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                _vm._l(_vm.courses, function (course, n) {
                                  return _c("card-course-small", {
                                    key: n,
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: {
                                      course: course,
                                      width: _vm.sliderItemWidth(),
                                    },
                                    on: {
                                      deleted: function ($event) {
                                        return _vm.removeMyCourseFromStore(
                                          course
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  style: _vm.eventsOffersStyle,
                  attrs: {
                    order: _vm.screenSize <= 800 ? 1 : 2,
                    cols: _vm.screenSize <= 800 ? "12" : "",
                  },
                },
                [
                  _vm.screenSize > 800
                    ? _c("div", [_c("list-recent-activity")], 1)
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }