import { Backend } from "~/utils/backend";
const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
const acctBackend = new Backend(
  process.env.VUE_APP_BACKEND_ACCT_URL + "/events"
);
import { checkStatus, Logger } from "/global/utils/helpers";

export async function getOwnStats($store) {
  const { data } = await backend
    .get("teachers_get_own_stats")
    .then(checkStatus);
  $store.commit("dashboard/setStats", data.content.stats);
  return data.content.stats;
}

export async function getOrganizationStats($store) {
  const { data } = await backend
    .get("teachers_get_org_stats")
    .then(checkStatus);
  $store.commit("dashboard/setOrgStats", data.content.stats);
  return data.content.stats;
}

export async function getTotalStats($store) {
  const { data } = await backend
    .get("teachers_get_total_stats")
    .then(checkStatus);
  $store.commit("dashboard/setTotalStats", data.content.stats);
  return data.content.stats;
}

export async function getRecentActivities($store) {
  const { data } = await backend
    .get("teachers_get_recent_activities")
    .then(checkStatus);
  $store.commit("dashboard/setRecentActivities", data.content.activities);
  return data.content.activities;
}
export async function getPendingActivities($store) {
  const { data } = await backend
    .get("teachers_get_pending_activities")
    .then(checkStatus);
  $store.commit("dashboard/setPendingActivities", data.content.activities);
  return data.content.activities;
}
export async function getSubscription() {
  const { data } = await acctBackend
    .get("payment_get_subscription")
    .then(checkStatus);
  return data.content;
}
