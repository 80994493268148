var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-style" },
    [
      _c(
        "p",
        { staticClass: "ft-avenir title-text-style d-flex align-center" },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c("v-divider"),
      _c(
        "p",
        { staticClass: "ft-avenir count-text-style d-flex align-center" },
        [_vm._v(" " + _vm._s(_vm.batchCount) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }