<template>
  <div class="card-style">
    <p class="ft-avenir title-text-style d-flex align-center">{{ title }}</p>
    <v-divider></v-divider>
    <p class="ft-avenir count-text-style d-flex align-center">
      {{ batchCount }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CardActivityPending",
  props: {
    title: {
      type: String,
      required: true
    },
    batchCount: {
      type: [String, Number],
      required: true,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    size() {
      return {
        batchCount: {
          fontSize: this.$vuetify.breakpoint.lgAndDown ? "27px" : "35px",
          margin: this.$vuetify.breakpoint.lgAndDown
            ? "12px 0px 12px 24px"
            : "15px 0 16px 36px"
        },
        title: {
          margin: this.$vuetify.breakpoint.lgAndDown
            ? "20px 0px 12px 24px"
            : "23px 0 16px 36px"
        }
      };
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.card-style {
  background-color: white;
  color: #b7b7b7;
  border: 1px solid #b7b7b777;
  border-radius: 6px;
  height: 100%;
  width: 100%;
}

.title-text-style {
  font-size: 16px;
  width: 100%;
  height: 50%;
  padding-left: 5%;
  color: #404040;
  line-height: normal;

  @media screen and (min-width: 600px) and (max-width: 1600px) {
    font-size: 14px;
    padding-left: 4%;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
    padding-left: 10px;
  }
}

.count-text-style {
  font-size: 24px;
  width: 100%;
  height: 50%;
  padding-left: 5%;
  color: black;
  font-weight: 600;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    padding-left: 10px;
  }
}
</style>
